import React from 'react'


export function MentionsLegales() {
  return (
    <main className='mentionsMain'>
      <h1 className="sous-titre">Mentions Légales</h1>
      <section>
        <article>
          <h3>Editeur :</h3>
          <p><b>LE BUREAU IMAGINAIRE</b>, collectif constitué de : </p>
          <p><b>CPFC</b> (SIRET : 433 582 566 00033) – 2 roquet de Patience – 53000 LAVAL</p>
          <p>Et</p>
          <p><b>SUZON ILLUSTRATION </b>(SIRET : 800 148 512 00034) – 3 place de l’église – 53470 SACE</p>
        </article>
        <article>
          <p><b>Directeur de la publication :</b> Christophe PICHON (christophe.pichon.pro@gmail.com)</p>
          <p><b>Directrice de la publication adjointe :</b> Suzanne JEANNEAU (jeanneau.suzanne@gmail.com)</p>
        </article>
        <article>
          <h3>Hébergeur :</h3>
          <p><b>OVH</b></p>
          <p>
            SAS au capital de 10 174 560 €
            <br />
            RCS Lille Métropole 424 761 419 00045
            <br />
            Code APE 2620Z
            <br />
            N° TVA : FR 22 424 761 419
            <br />
            Siège social : 2 rue Kellermann - 59100 Roubaix - France
          </p>
        </article>
        <article>
          <h3>Propriété et Droit d'auteur</h3>
          <p>
            La structure générale, ainsi que les logiciels, textes, images animées ou non, son savoir faire, et tous
            autres éléments composants le site sont la propriété exclusive du Bureau Imaginaire. Toute
            représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l'autorisation
            expresse du Bureau Imaginaire est interdite et constituerait une contrefaçon sanctionnée par les
            articles L.335-2 et suivants du Code de la propriété intellectuelle. Il en est de même des bases de
            données figurant sur le site web, qui sont protégées par les dispositions du Code de la propriété
            intellectuelle portant transposition de la directive européenne du 11 mars 1996 relative à la
            protection juridique des bases de données, et dont le Bureau Imaginaire est producteur. Tout
            utilisateur ou visiteur du site web ne peut mettre en place un hyperlien en direction de ce site sans
            l'autorisation expresse et préalable du Bureau Imaginaire.
          </p>
        </article>
      </section>
    </main>
  )
}

