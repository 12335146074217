import * as React from "react";
import { Routes, Route} from "react-router-dom";

/*COMPONANT */
import {Header} from "./Components/Header/Header.js"
import {Footer} from "./Components/Footer/Footer.js"

/*PAGES */
import {Home} from "./Pages/Home/Home.js";

import {FabriqueProjet} from "./Pages/FabriqueProjet/FabriqueProjet.js";

import {TravaillerEnsemble} from "./Pages/TravaillerEnsemble/TravaillerEnsemble.js";

import NosRealisations from "./Pages/NosRealisations/NosRealisations.js";
import Projet from './Pages/NosRealisations/Projet/Projet.js';

import { QuiSommesNous } from './Pages/QuiSommesNous/QuiSommesNous.js';

import { PrivateRoute } from './Pages/privateRoute';
import { LoginForm } from './Pages/Admin/LoginForm.js';
import { PanelAdmin } from './Pages/Admin/PanelAdmin';


import { ParallaxProvider } from 'react-scroll-parallax';
import { MentionsLegales } from "./Pages/MentionsLegales.js";
import { ProtectionDonnees } from "./Pages/ProtectionDonnees.js";


function App() {

  return (
    <div className="App">
      <Header />
        <ParallaxProvider>
          <Routes>
            <Route path="/" element={<Home />} title="Home"/>
            <Route path="/FabriqueProjet" element={<FabriqueProjet />} />
            <Route path="/TravaillerEnsemble" element={<TravaillerEnsemble />} />
            <Route path="/NosRealisations" element={<NosRealisations />} />
            <Route path="/NosRealisations/:id" element={<Projet />} />
            <Route path="/APropos" element={<QuiSommesNous />} />
            <Route path="/BILoginForm" element={<LoginForm />} />
            <Route path="/MentionsLegales" element={<MentionsLegales />} />
            <Route path="/ProtectionDonnees" element={<ProtectionDonnees />} />

            <Route element={<PrivateRoute isLogged={sessionStorage.isLogged}/>} >
              <Route path="/BIAdminPanel" element={<PanelAdmin />}/>
            </Route>
            
          </Routes>
        </ParallaxProvider>
      {Location.pathname !== 'NosRealisations' && <Footer />}
    </div>
  );
}

export default App;
