export const LoginData = {
    username: "LBIadmin",
    password: "BureauIma123456"
}

export const DataTest =  [
    {
      id: 0,
      title: "Myrtille",
      contenu: "un scénario inspiré d’Alice au Pays des Merveilles, où les joueurs, aidés par le FBI (Fabuleux Bureau Imaginaire) doivent récolter et répondre aux énigmes laissées dans les locaux de la bibliothèque par Myrtille, une ancienne usagère recherchée pour trafic de drogue.",
      images: [
        "./images/projet1/6.jpg" ,
        "./images/projet1/pres2.jpg"
      ]
    } , { 
      id: 1,
      title: "Le voyageur",
      contenu: "L’histoire d’un mystérieux employé de la médiathèque qui cherche à retrouver la mémoire auprès des joueurs. Inspiré de Docteur Who, ce jeu invite les joueurs à découvrir les différents espaces de la médiathèque ainsi que les technologies proposées par le Fab Lab.",
      images: [
        "./images/projet2/pres1.jpg" ,
        "./images/projet2/4.jpg"
      ]
    } , {
      id: 2,
      title: "Wells-Mitchell",
      contenu: "L’histoire d’un mystérieux employé de la médiathèque qui cherche à retrouver la mémoire auprès des joueurs. Inspiré de Docteur Who, ce jeu invite les joueurs à découvrir les différents espaces de la médiathèque ainsi que les technologies proposées par le Fab Lab.",
      images: [
        "./images/projet3/1.png" ,
        "./images/projet3/2.png"
      ]
    }
  ]