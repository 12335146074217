import React from 'react'

export function ProtectionDonnees() {
  return (
    <main className='protectionMain'>
        <h1 className='sous-titre'>Protection des données</h1>

        <section>
            <h2>INFORMATIONS RELATIVES A LA PROTECTION DE VOS DONNEES PERSONNELLES</h2>
            <article>
                <p>
                    Le présent document vous informe sur la manière dont CPFC et SUZON ILLUSTRATION en leur
                    qualité de responsables conjoints de traitement traitent vos données personnelles dans le contexte du
                    site « BUREAU IMAGINAIRE» (https://bureauimaginaire.com/) (ci-après le « Site »).
                </p>

                <p>
                    Le Site est destiné à permettre à des prospects de nous contacter pour obtenir des informations, ainsi
                    qu’à détailler les activités du collectif Bureau Imaginaire.
                </p>
            </article>
            <article>
                <h4>Finalités des traitements</h4>
                <p>
                    Vos données personnelles font l’objet de traitements par CPFC et SUZON ILLUSTRATION, pour les
                    finalités énumérées ci-dessous, sur les bases juridiques suivantes :
                </p>
                <ul>
                    <li>
                        Pour gérer les demandes de contact et de devis via les formulaires disponibles sur le Site : le
                        traitement de vos données personnelles est dans ce cadre fondé sur votre consentement. Le
                        traitement desdites données est nécessaire à l’utilisation du formulaire de contact proposé par
                        le site. La non-fourniture des données a pour conséquence de ne pas permettre l’exploitation
                        de votre message.   
                    </li>
                    <li>
                        Pour recueillir votre avis s’agissant de la qualité des services et produits proposés, dans notre
                        intérêt légitime à vous offrir la meilleure qualité possible de service.
                    </li>
                    <li>
                        Pour que nous puissions élaborer des statistiques relatives à l’utilisation du Site, dans notre
                        intérêt légitime à vous fournir une plateforme fonctionnelle et stable et répondant à vos
                        besoins.
                    </li>
                    <li>
                        Pour assurer le traitement et le suivi de l’exercice de vos droits dans le respect de la
                        règlementation protection des données personnelles.
                    </li>
                    <li>
                        Lorsque le traitement est réalisé sur la base de votre consentement, vous avez la possibilité
                        de retirer votre consentement. Le retrait du consentement engendrant l’interruption de l’accès
                        aux prestations concernées.
                    </li>
                </ul>
            </article>
            <article>
                <h4>Destinataires de vos données personnelles</h4>
                <p>
                    En raison de leurs fonctions et dans la limite de leurs attributions respectives, vos données
                    personnelles sont destinées, le cas échéant, aux services internes habilités au sein du collectif
                    BUREAU IMAGINAIRE.
                    Vous êtes aussi informé que conformément aux lois et réglementations en vigueur, vos données
                    personnelles peuvent être transmises aux autorités compétentes, à leur demande, en leur qualité des
                    Tiers Autorisés.
                </p>
            </article>
            <article>
                <h4>Durées de conservation</h4>
                <p>
                    Vos données personnelles sont conservées pour la durée nécessaire aux opérations pour lesquelles
                    elles ont été collectées ainsi que dans le respect de la réglementation en vigueur.
                    Ainsi les données à caractère personnelles traitées dans le cadre de prospection commerciale, sont
                    conservées 3 ans à compter de la dernière relation émanant du prospect.
                </p>
                <p>
                    Par ailleurs, CPFC et SUZON ILLUSTRATION sont aussi amenés à conserver, en archive, certaines
                    de vos données personnelles en vue d’être en mesure de répondre à leurs obligations légales et à
                    toute action en justice, et ce pendant la durée de prescription prévue par la législation applicable.
                </p>
            </article>
            <article>
                <h4>Transfert en dehors de l’Union Européenne</h4>
                <p>
                    Les données personnelles collectées et traitées par CPFC et SUZON ILLUSTRATION ne font l’objet
                    d’aucun transfert hors de l’Union Européenne.
                    En cas de transfert des données personnelles vers un pays tiers, CPFC et SUZON ILLUSTRATION
                    prendront les garanties appropriées, notamment contractuelles, afin d’assurer la protection de vos
                    données et la conformité avec la réglementation applicable en matière de protection des données
                    personnelles.
                </p>
            </article>
            <article>
                <h4>Vos droits relatifs à la protection des données personnelles</h4>
                <p>
                    Vous disposez d’un droit d’accès aux données personnelles vous concernant, du droit d’en demander
                    la rectification, l’effacement ou la portabilité, ainsi que du droit de demander la limitation du traitement
                    de vos données personnelles, de retirer votre consentement et de définir des directives sur le sort de
                    vos données après votre mort, le cas échéant.
                    Vous avez également la possibilité de vous opposer, à tout moment, pour des raisons tenant à votre
                    situation particulière, aux traitements fondés sur l’intérêt légitime de CPFC et SUZON
                    ILLUSTRATION.
                </p>
                <p>Ces droits peuvent être exercés en contactant :</p>
                <p>
                    CPFC <br />
                    2, roquet de Patience <br />
                    53000 LAVAL <br />
                    (christophe.pichon.pro@gmail.com)
                </p>
                <p>Ou</p>
                <p>
                    SUZON ILLUSTRATION <br />
                    2, roquet de Patience <br />
                    53000 LAVAL <br />
                    (christophe.pichon.pro@gmail.com)
                </p>
                <p>Un justificatif d’identité pourra éventuellement vous être demandé.</p>
                <p>Vous disposez également du droit d’introduire une réclamation auprès de la Commission nationale de l’Informatique et des Libertés (CNIL).</p>
            </article>
            <article>
                <h4>Sécurisation des données</h4>
                <p>
                    L’ensemble des données est stocké sur les serveurs de l’hébergeur OVH, auquel CPFC et SUZON
                    ILLUSTRATION ont confié délégation pour la sécurisation des données personnelles.
                </p>
            </article>
            <article>
                <h4>Modification de l’information données personnelles</h4>
                <p>
                    Le présent document peut être amené à évoluer, afin d’assurer une information adéquate sur les
                    traitements.
                    Edition AOUT 2022.
                </p>
            </article>
        </section>
    </main>
  )
}

