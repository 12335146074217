import * as React from "react";
import { useEffect } from "react";
import { useNavigate , useLocation} from "react-router-dom";
import Aos from "aos";
import 'aos/dist/aos.css';


import './NosRealisations.css';

 
function ListItem({id, title, image}){

    function setYoffset(){
        window.scrollTo(0, 0);
    }

    const navigate = useNavigate();

    return (
        <article className="sample-card-realisation" onClick={() => {navigate(id); setYoffset();}}>
            <div>
                <p>0{parseInt(id) + 1}</p>
                <h2>{title}</h2>
            </div>
            <div className="img-header-realisation"> 
                <img 
                src={image}
                alt="projet myrtille">
                </img>
                
            </div>
            <p className="absolute-button">Découvrir</p>
        </article>
    )
}

export default function NosRealisations(){  


    const data = [
        {
            id: "0",
            titre: "Myrtille",
            image: "./images/projet1/6.jpg" 
        } , 
        {
            id: "1",
            titre: "Le voyageur",
            image: "./images/projet2/pres1.jpg"
        } , 
        {
            id: "2",
            titre: "Wells-Mitchell",
            image: "./images/projet3/2.jpg"
        }
    ]

    const { pathname } = useLocation();

    const locationPath = pathname.split('/')

    console.log(locationPath)
   
  
    useEffect(() => {
        if(locationPath[1] === 'NosRealisations'){
            Aos.init({duration: 1000});
    
            const spaceHolder = document.querySelector('.space-holder');
            const horizontal = document.querySelector('.horizontal');
            
    
            function calcDynamicHeight(ref) { 
            const vw = window.innerWidth;
            const vh = window.innerHeight;
            const objectWidth = ref.scrollWidth;
            return objectWidth - vw + vh + 150; // 150 is the padding (in pixels) desired on the right side of the .cards container. This can be set to whatever your styles dictate
            }
    
            spaceHolder.style.height = `${calcDynamicHeight(horizontal)}px`;
    
            window.addEventListener('scroll', () => {
            const sticky = document.querySelector('.sticky');
            horizontal.style.transform = `translateX(-${sticky.offsetTop}px)`;
            });
            
            window.addEventListener('resize', () => {
            spaceHolder.style.height = `${calcDynamicHeight(horizontal)}px`;
            });
        }
    }, [])
    
    return (
        <main className="main-realisations">
            <h1 className="titre">Nos réalisations</h1>
            <section className="container-horizontal container-horizontal-realisations">
                <div className="space-holder">
                    <div className="sticky">
                    <div className="horizontal">
                        <section role="feed" className="cards">
                           
                            <ListItem id={data[0].id} title={data[0].titre} image={data[0].image} />
                            <ListItem id={data[1].id} title={data[1].titre} image={data[1].image} />
                            <ListItem id={data[2].id} title={data[2].titre} image={data[2].image} />
                            
                        </section>
                    </div> 
                    </div>
                </div>
            </section> 

        </main>
        
    )
} 
