import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";

import './FormFabriqueProjet.css'

const Data = [
    {
        page: 0,
    } ,
    {
        page: 1,
        nom: "Durée",
        name: "duree",
        classname: "field-duree",
        step: [
            {
                value: "1 heure"
            },
            {
                value: "2 heures"
            },
            {
                value: "3 heures"
            },
            {
                value: "Une journée"
            },
            {
                value: "Autre durée"
            },
        ]

    } ,
    {
        page: 2,
        nom: "Nombre de participants",
        name: "nombre",
        classname: "field-nbr-participant",
        step: [
            {
                value: "1 à 3"
            },
            {
                value: "4 à 6"
            },
            {
                value: "7 à 12"
            },
            {
                value: "13 à 25"
            },
            {
                value: "+ de 25"
            },
        ]

    },  {
        page: 3,
        nom: "Nombre de sessions",
        name: "session",
        classname: "field-nbr-session",
        step: [
            {
                value: "Une seule session"
            },
            {
                value: "Plusieurs sessions dans la même journée"
            },
            {
                value: "Plusieurs sessions dans la même semaine"
            },
            {
                value: "Plusieurs sessions dans le même année"
            },
        ]

    }, {
        page: 4,
        nom: "Type de jeu",
        name: "type",
        classname: "field-type-jeu",
        step: [
            {
                value: "Escape game"
            },
            {
                value: "jeu d'enquête"
            },
            {
                value: "Coaching scénarisé"
            },
            {
                value: "Jeu de piste scénarisé"
            },
            {
                value: "Jeu de société personnalisé"
            }, 
            {
                value: "Autre jeu"
            }
        ]

    }, {
        page: 5,
        nom: "Difficulté",
        name: "difficulte",
        classname: "field-difficulte",
        step: [
            {
                value: "Grand public"
            },
            {
                value: "Joueurs habitués"
            },
            {
                value: "Spécialistes du sujet"
            }
        ]

    }, {
        page: 6,
        nom: "Un handicap à prendre en compte ?",
        name: "handicap",
        classname: "field-handicap",
        step: [
            {
                value: "Oui : physique"
            },
            {
                value: "Oui : mental"
            },
            {
                value: "Oui : physique et mental"
            },
            {
                value: "Aucun handicap"
            }
        ]

    }, {
        page: 7,
        nom: "Budget alloué",
        name: "budget",
        classname: "field-budget",
        step: [
            {
                value: "Budget approximatif"
            },
            {
                value: "Budget ferme"
            },
            {
                value: "Pas de notion pour le budget"
            }
        ]

    }, {
        page: 8,
        nom: "Lieu",
        name: "lieu",
        classname: "field-lieu",
        step: [
            {
                value: "Dans mon entreprise"
            },
            {
                value: "Dans un lieu à privatiser"
            },
            {
                value: "Sur la voie publique"
            } , {
                value: "Autre lieu"
            }
        ]

    },  {
        page: 9,
        nom: "Zone géographique",
        name: "zone",
        classname: "field-zone-geographique",
        step: [
            {
                value: "Pays de la Loire"
            },
            {
                value: "Ile de France"
            },
            {
                value: "Bretagne"
            } , 
            {
                value: "Centre Val-De-Loire"
            } , 
            {
                value: "Normandie"
            } , 
            {
                value: "Autre"
            }
        ]

    } ,  {
        page: 10,
        nom: "Enjeux & objectifs",
        name: "enjeux",
        classname: "field-enjeux",
        step: [
            {
                value: "Team building"
            },
            {
                value: "Identifier des profils évolutifs"
            },
            {
                value: "Communication"
            } , 
            {
                value: "Softskills"
            } , 
            {
                value: "Créativité et Agilité"
            } , 
            {
                value: "Autre"
            }
        ]

    } , {
        page: 11,
        nom: "Choix du thème / sujet",
        name: "theme",
        classname: "field-theme",
        step: [
            {
                value: "J'ai un thème imposé"
            },
            {
                value: "Le thème est proposé par le Bureau Imaginaire"
            }
        ]

    } , {
        page: 12,
        nom: "Méthode d'animation",
        name: "animation",
        classname: "field-animation",
        step: [
            {
                value: "Jeu animé de manière autonome"
            },
            {
                value: "Jeu animé par le Bureau Imaginaire"
            }
        ]

    } , {
        page: 13,
        nom: "Contenu numérique",
        name: "contenu",
        classname: "field-contenu-numerique",
        step: [
            {
                value: "Je souhaite éviter les outils numériques"
            },
            {
                value: "Je souhaite que le jeu intègre des outils numériques"
            } ,
            {
                value: "Je n'ai pas d'avis"
            }
        ]

    }
]


function Step({name, id, value, onRadioChange}){

    
    const handleChange = (e) => {
        onRadioChange(e)
    }

    return (
        <div>
            <input type="radio" name={name} id={id} value={value} onClick={handleChange}/>
            <label htmlFor={id}>{value}</label>
        </div>
    )
}

function ListStep({ data, currentPage}){


    const [radioValue, setRadioValue] = useState({})

    const handleChange = (e) => {
        setRadioValue({... radioValue, [e.target.name]: e.target.value})
    }

    const filtre = data.filter((item, index) => {
        return index === currentPage
    })

    const result = Object.keys(radioValue).map(key => {
        return {[key]: radioValue[key]};
    })

    const [finalData, setFinalData] = useState()

    function SendEmail(){

        setFinalData(JSON.stringify(radioValue))
        return (
            <div>
                <input type='hidden' name='projet' value={finalData}></input>
            </div>
        )
    }

    function returnTitre(titre){

        if(titre[0] === "duree"){
            return titre[0] = "Durée"
        } 
        
        if (titre[0] === "nombre"){
            return titre[0] = "Participants"
        }

        if (titre[0] === "session"){
            return titre[0] = "Nombre de sessions"
        }

        if (titre[0] === "type"){
            return titre[0] = "Type de jeu"
        }

        if (titre[0] === "difficulte"){
            return titre[0] = "Difficulté"
        }

        if (titre[0] === "handicap"){
            return titre[0] = "Un handicap ?"
        }

        if (titre[0] === "budget"){
            return titre[0] = "Budget alloué"
        }

        if (titre[0] === "lieu"){
            return titre[0] = "Lieu"
        }

        if (titre[0] === "zone"){
            return titre[0] = "Zone géographique"
        }

        if (titre[0] === "enjeux"){
            return titre[0] = "Enjeux & Objectifs"
        }

        if (titre[0] === "theme"){
            return titre[0] = "Choix du thème"
        }

        if (titre[0] === "animation"){
            return titre[0] = "Méthode d'animation"
        }

        if (titre[0] === "contenu"){
            return titre[0] = "Contenu numérique"
        }
    }

            

    function Recap(){

        const Testing = (index) => {
            

            if (index[0] === "duree"){
                delete radioValue.duree
                setRadioValue({...radioValue})
            }

            if (index[0] === "nombre"){
                delete radioValue.nombre
                setRadioValue({...radioValue})
            }

            if (index[0] === "session"){
                delete radioValue.session
                setRadioValue({...radioValue})
            }

            if (index[0] === "type"){
                delete radioValue.type
                setRadioValue({...radioValue})
            }

            if (index[0] === "difficulte"){
                delete radioValue.difficulte
                setRadioValue({...radioValue})
            }

            if (index[0] === "handicap"){
                delete radioValue.handicap
                setRadioValue({...radioValue})
            }

            if (index[0] === "budget"){
                delete radioValue.budget
                setRadioValue({...radioValue})
            }

            if (index[0] === "lieu"){
                delete radioValue.lieu
                setRadioValue({...radioValue})
            }

            if (index[0] === "zone"){
                delete radioValue.zone
                setRadioValue({...radioValue})
            }

            if (index[0] === "enjeux"){
                delete radioValue.enjeux
                setRadioValue({...radioValue})
            }

            if (index[0] === "theme"){
                delete radioValue.theme
                setRadioValue({...radioValue})
            }

            if (index[0] === "animation"){
                delete radioValue.animation
                setRadioValue({...radioValue})
            }

            if (index[0] === "contenu"){
                delete radioValue.contenu
                setRadioValue({...radioValue})
            }

        }

        if(currentPage !== 0){
            return (
                <section>
                    {result[0] ? <h3 className="titre-recap">Récapitulatif</h3> : ""}
                    <div className={`recapitulatif-section ${currentPage === 14 ? "recap-column" : ""}`}>
                        
                        {
                            result.map((item) => {
                                return (
                                    
                                    <div onClick={() => {Testing(Object.keys(item))}}>
                                        <b>{returnTitre(Object.keys(item))} </b>
                                        <p>{Object.values(item)}</p>
                                    </div>  
                                    
                                )
                            })
                        }
                    </div> 
                </section>
            )
        }
    }
    

    return (
        <article>
        {
        filtre.map((item, index) => {
            if (currentPage > 0){
                return (
                    
                    <fieldset key={index} className={`form-field ${item.classname}`}>
                        <legend>{item.nom}</legend>
                        {
                            item.step.map((etape, index) => {
                                return (
                                    <Step  name={item.name} id={etape.value} value={etape.value} onRadioChange={handleChange}/>
                                )
                                
                            })
                        }
                    </fieldset>
                    
                    
                )
            } if ( currentPage === 0){                
                return (
                    <div>
                        <h2 className="fabrique-sous-titre">Une activité ludique pour vos équipes ou votre public ?</h2>
                        <p className="fabrique-desc">Faites vos jeux dans les différentes options (non exhaustives) que nous vous soumettons ici : nous reviendrons vers vous pour vous proposer une offre adaptée à vos besoins et envies !</p>
                        <p className="fabrique-desc2">Une seule limite : votre imagination !</p>
                    </div>
                )
            }
           
        })
        }   
        <Recap currentPage={currentPage} />
        <SendEmail />
        </article>
    )
}



function Progress({data, currentPage}){

    const filtreProg = data.filter((item, index) => {
        return item.page != 0
    })


    if( currentPage !== 0 && currentPage !== 14){
         return (
            <div className="prog-fabrique">
                {filtreProg.map((item, index) => {
                return (
                    <p 
                        className={`step-number ${index <= currentPage - 2 ? "active" : ""}`}
                        style={{
                            background: index <= currentPage - 1 ? "#CA412F" : "", 
                            color : index <= currentPage - 1 ? "white" : "black",
                            fontWeight: index <= currentPage -1 ? "bold" : ""}}
                        >
                    </p>
                    )
                })}
            </div>
        )
    }

   
}

export default function FormFabriqueProjetComponent(){

    const [currentPage, setCurrentPage] = useState(0);
    const [nombre, setNombre] = useState(Data.length);

    const [state, setState] = useState(false)

    const handleSubmit = (e) => {
        console.log(e)
        if(currentPage !== 14){
            e.preventDefault()
        }

        if (state === false){
            e.preventDefault()
        }
    }

    const handleState = () => {
        setState(true)
    }

    return (
        <div className="form-container-fabrique">

            <form method="post" action="https://formsubmit.co/max.machin@laplateforme.io" onSubmit={handleSubmit} >
            
            <Progress data={Data} currentPage={currentPage} />

            <ListStep data={Data} currentPage={currentPage} />
           
            {
                currentPage === 0 ?
                    <div className="step-form-control">
                        <button className="step-form-fabrique" onClick={() => {currentPage >= nombre ? setCurrentPage(nombre) : setCurrentPage(currentPage + 1)}}>Commencer</button>
                    </div> 

                : currentPage === 14 ? 

                    <div className="step-form-control2">
                        <button className="step-form-fabrique" onClick={() => {currentPage <= 0 ? setCurrentPage(0) : setCurrentPage(currentPage - 1)}}>Retour</button>
                        <button className="step-form-fabrique" type="submit" onClick={handleState}>Créer mon projet</button>
                        
                    </div>
                :
                    
                    <div className="step-form-control2">
                        <button className="step-form-fabrique" onClick={() => {currentPage <= 0 ? setCurrentPage(0) : setCurrentPage(currentPage - 1)}}>Précedent</button>
                        <button className="step-form-fabrique" onClick={() => {currentPage >= nombre ? setCurrentPage(nombre) : setCurrentPage(currentPage + 1)}}>Suivant</button>
                    </div>  
                     
            }

            </form>
        </div>
    )
}