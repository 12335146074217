import React from "react";
import './quiSommesNous.css'


export function QuiSommesNous() {

    return (
        <main>
            <h1 className="titre">Qui sommes-nous ? </h1>
            <section className="section-entreprise">
                <ul>
                    <li>
                        <p>Créativité</p>
                        <p>Les projets que nous réalisons ont toujours une identité visuelle et une direction artistique forte. Nous recherchons l'originalité aussi bien que le visuellement réussi.</p>
                    </li>
                    <li>
                        <p>Confiance</p>
                        <p>	Il est  primordial que nous définissions ensemble très tôt les limites que vous imposez dans votre projet : plus elles seront nombreuses, plus il sera complexe de répondre à vos attentes et objectifs. Nous vous invitons à nous faire confiance, en nous laissant le plus possible de liberté, afin d'optimiser votre investissement : c'est souvent en prenant de la distance et en travaillant en rupture qu'on propose les choses les plus pertinentes !</p>
                    </li>
                    <li>
                        <p>Fiabilité</p>
                        <p>	La tenue des engagements et des délais est centrale dans notre métier, et nous sommes particulièrement attentifs à démontrer tout au long de la vie du projet notre attachement à cette valeur.</p>
                    </li>
                    <li>
                        <p>Transparence</p>
                        <p>	Notre mode de fonctionnement doit vous permettre d'avoir le plus possible de visibilité sur l'avancée et le contenu du projet. De cette manière, pas de surprise à la livraison : le produit découlera logiquement du travail que vous aurez régulièrement suivi et validé !</p>
                    </li>
                </ul>
            </section> 
            
            <section className="section-apropos">
                <article className="img-container">
                    <img src="/images/carte.jpeg" alt="vielle carte vintage avec ecriture noire"></img>
                </article>
                <article>
                    <h2>L'entreprise</h2>
                    <p> Le Bureau Imaginaire est un collectif de créateurs de jeux mis en place en 2021. Le Bureau Imaginaire est constitué à ce jour
                        de Christophe, spécialiste en game design et narratice design, et de Suzanne, illustratrice et graphiste professionnelle. En tant 
                        que collectif, la structure du Bureau Imaginaire est particulièrement souple et nous permet d'intégrer d'autres créateurs
                        ponctuellement, ou, très bientôt, de manière plus pérenne. Nous sommes très attachés à certaines valeurs, il nous apparait 
                        important de vous les partager : pour travailler bien, il faut se connaître bien !
                    </p>
                </article>
                
                <article className="img-container">
                    <img src="/images/carte.jpeg" alt="vielle carte vintage avec ecriture noire"></img>
                </article>
            </section>

            <section className="section-equipe">

                <h2>L'équipe</h2>

                <article className="grid-gallery-team">
                    <figure className="gallery-item">
                        <img src="/images/team.jpg" className="img-gallery-team" alt="presentation des membres de l'équipe"></img>
                    </figure>
                    <figure className="gallery-item">
                        <img src="/images/team2.jpg" className="img-gallery-team" alt="presentation des membres de l'équipe"></img>
                    </figure>
                    <figure className="gallery-item">
                        <img src="/images/team3.jpeg" className="img-gallery-team" alt="presentation des membres de l'équipe"></img>
                    </figure>
                </article>


                <article className="detail-team-article">
                    <div>
                        
                        <img src="/images/equipe2.jpeg" alt="membre de l'equipe seul"></img>

                        <aside>
                            <h3>Suzanne</h3> 
                            <p>
                            Originaire d’Angers, Suzanne vient s’installer en 2014 à Laval en tant que graphiste dans 
                            une chocolaterie Mayennaise. En 2018, elle renoue avec sa première passion et se lance 
                            professionnellement dans l’illustration.Elle cherche au travers de ses créations à 
                            inviter au voyage grâce à des thèmes et un trait qui parle à tous, petits et grands.
                            Suzanne travaille avec différentes techniques, de l’aquarelle à la peinture numérique en 
                            passant par l’encre de chine.Depuis plusieurs années elle collabore avec 
                            le service Patrimoine de Laval sur des projets ludiques. (Jeu des 7 familles, Défi Frise et 
                            ateliers Tot’M ton KaRtier).En 2021, elle a également réalisé la direction artistique pour des 
                            Escape Game : Bibliothèque Départementale de Loire Atlantique et Médiathèque de Mayenne (en cours).
                            </p>  
                        </aside>
                        
                        
                    </div>
                    
                    <div>
                    <aside>
                        <h3>Christophe</h3>
                        <p>
                        Après une carrière professionnelle dans l’informatique (l’Unité Centrale) et les télécoms 
                        (SFR), Christophe décide en 2017 de démarrer son activité de consultant en formation. 
                        Dans ce cadre, il cherche particulièrement à développer les aspects de gamification qui 
                        permettent des apprentissages rapides et efficaces, mais surtout durables !
                        C’est donc tout naturellement que Christophe travaille depuis sur des projets variés 
                        impliquant le jeu sous différentes formes, pour le compte d’entreprises publiques ou privées 
                        (coaching scénarisé visant à développer les savoir-être pour Orange, Escape Game pour 
                        aider au retour à l’emploi pour Pôle Emploi, Escape Games pour la 
                        Médiathèque de Laval, pour la médiathèque de Mayenne et pour la 
                        Bibliothèque Départementale de Loire Atlantique, jeu de plateau et 
                        ateliers ludiques pour Nexity, narrative design pour des parcours au 
                        Jardin des Plantes de Paris…)
                        Dans un contexte associatif, Christophe scénarise et co-organise des 
                        jeux de rôle Grandeur Nature pour 2 à 50 participants.
                        Sa démarche consiste à travailler sur des projets qui l’enthousiasment, 
                        et dans lesquels il s’investit pleinement pour apporter aux participants 
                        à la fois plaisir et compétences.
                        </p>
                    </aside>
                        
                        <img src="/images/equipe1.jpeg" alt="membre de l'équipe seule"></img>
                    </div>
                    
                    
                </article>

            </section>

        </main>
    )
}