import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { LoginData } from '../Data'

export function LoginForm() {

  const navigate = useNavigate()

  const [ data, setData ] =  useState({
    login: "",
    password: ""
  })

  const [isError, setIsError] = useState(false)
  const [error, setError] = useState("")
  
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  function Login(e){
    e.preventDefault();
    if (!data.login){
      setIsError(true)
      setError('Veuillez renseigner votre login')
    }
    else if (!data.password){
      setIsError(true)
      setError('Veuillez renseigner votre mot de passe')
    } 
    else if (data.login !== LoginData.username) {
      setIsError(true)
      setError('Login ou mot de passe incorrect')
    } 
    else if (data.password !== LoginData.password) {
      setIsError(true)
      setError('Login ou mot de passe incorrect')
    }else {
      sessionStorage.setItem("isLogged", true);
      navigate("/BIAdminPanel")
    }
  }

  
  return (
    <main>
      <div>
        <form onSubmit={Login} method="post">

          <article>
            <label htmlFor='Login'>Login</label>
            <input 
              type="text" 
              id="login" 
              name="login" 
              placeholder="login" 
              onChange={handleChange}
              value={data.login}
              >

            </input>
          </article>

          <article>
            <label htmlFor='password'>Mot de passe</label>
            <input 
              type="password" 
              id="password" 
              name="password" 
              placeholder="password" 
              onChange={handleChange}
              value={data.password}
              >
            </input>
          </article>
          <p>{isError ? error : ""}</p>
          <input type="submit" name='submit' value="connexion">

          </input>
        </form>
      </div>
    </main>
  )
}