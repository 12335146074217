import * as React from "react";
import { useState, useEffect } from "react";
import Circle from '../../Components/Circle';
import './TravaillerEnsemble.css'

// import emailJs from 'emailjs-com'

import {FaPhoneAlt} from "react-icons/fa"
import {MdEmail} from "react-icons/md"



export function TravaillerEnsemble(){   

    const titles = [
        "Réunion d'échange préalable",
        "Présentation de notre devis sur mesure, et d'un rétroplanning",
        "Points d'étape et validations",
        "Livraison du produit",
        "Débriefing et enquête de satisfaction auprès des participants (optionnelle)"
    ]

    const data = [
        "Au cours de ce premier échange, nous définissons les contours de votre projet : contexte, objectifs, attentes spécifiques. Nous vous apportons également nos conseils basés sur notre expérience, et nos premières idées !",
        "Cette réunion doit nous permettre de nous mettre d'accord sur les aspects administratifs d'une part, mais également sur les jalons auxquels le Bureau Imaginaire s'engage.",
        "La création est désormais lancée, nous sommes en contact régulièrement : vous validez selon un calendrier défini en étape 2 l'avancement du projet (scénario, direction artistique, game design…)",
        "Nous vous livrons le produit que nous nous étions engagé à créer, et dont vous avez pu suivre la maturation pendant plusieurs semaines. Au-delà de l'activité ludique elle-même, nous livrons bien entendu tous les matériels et documents potentiellement nécessaires à l'animation (si vous avez choisi de l'animer par vous-mêmes).",
        "A l'issu de l'activité, nous vous proposons de recueillir par écrit et à chaud les réactions des participants, afin d'évaluer le plus objectivement possible la réussite du projet. De plus, nous vous proposons une réunion de débriefing ensemble afin de nous assurer de votre satisfaction, et potentiellement d'actions à mettre en place."
    ]

    const [circle] = useState(5)

    const [active, setActive] = useState(0)

    const [height, setHeight] = useState(0)

    useEffect(() => {
        setHeight(100 / (circle - 1) * active)
    }, [circle, active])

    const array = []

    for(let i = 0; i < circle ; i++){
    
        array.push(<Circle 
                        className={i <= active ? "circle active" : "circle"} 
                        key={i} 
                        classNameTitle={i <= active ? "circle-container active" : "circle-container"}
                        title={titles[i]}
                    >
                        {i}
                    </Circle>)
    }


    const [isError, setIsError] = useState(false)
    const [error, setError] = useState("")
    const [isValide, setIsValide] = useState(false)
    const [validation, setValidation] = useState("")

    const [toSend, setToSend] = useState({
        nom: '',
        email: '',
        message: '',
       
    });

    /*E-mail RegEx verification */
    const emailValidation = (e) => {
        const regEx = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (regEx.test(e.target.value)) {
        
        setIsError(false)
        setError("");
        e.target.style.border = ""
        } else if (!regEx.test(e.target.value) && e.target.value !== "") {
        setIsError(true)
        setError("Adresse e-mail invalide");
        e.target.style.borderColor = "red"
        } else {
        setError("");
        setIsError(false)
        e.target.style.border = ""
        }
    };

    const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    function sendEmail(e) {

        if(!toSend.nom){
            e.preventDefault();
            setIsError(true)
            setError("Veuillez renseigner votre nom")
        }

        else if (!toSend.email){
            e.preventDefault();
            setIsError(true)
            setError("Veuillez renseigner votre e-mail")
        }

        else if (!toSend.message){
            e.preventDefault();
            setIsError(true)
            setError("Veuillez insérer un message")
        }

        else if (toSend.message.length < 20){
            e.preventDefault();
            setIsError(true)
            setError("Votre message doit contenir au minimum 20 caractères")
        } else if ( toSend.nom , toSend.email, toSend.message, toSend.message.length < 20 ){
            setIsValide(true)
            setValidation("E-mail envoyer avec succés")
        }
    }


    


    return (
        <div>
            <main>
            <h1 className="titre">Travailler Ensemble</h1>

            <article className="center containerEnsemble">

                <p className="desc">
                    D’une manière globale, nous travaillons en transparence sur l’avancée du projet et soumettons à votre validation 
                    l’ensemble des éléments structurants ( moodboard, scénario, game design… )
                </p>

            </article>

            <section className="container-travailler-ensemble">
                <article className="content-travailler-ensemble">
                    <div className="progress-bar">
                        <div 
                            className="progress"
                            style={{height: height + "%"}}
                        >
                        </div>
                        {array}
                    </div>
                    <div className="content-progress">
                        <button  
                            style={{display: active > 0 ? "" : "none"}}
                            onClick={() => {active <= 0 ? setActive(0) : setActive(active-1)}}
                        >
                            
                            <div class="chevron-progress"></div>
                        </button>   

                        <div>
                            <h3><span>{active}</span>{titles[active]}</h3>
                            <p>{data[active]}</p>

                            {active >= circle -1 ? 
                            <a href="#contact-anchor">Contactez nous</a>
                                
                            : ""}   
                        </div>
                        

                        <button  
                            style={{display: active >= circle - 1 ? "none" : ""}}
                            onClick={() => active >= circle ? setActive(circle) : setActive(active+1)}
                        >
                            <div class="chevron-progress"></div>
                        </button>    
                    </div>
                </article> 
            </section>

            <section className="contact-section">
                <h2 id="contact-anchor">&bull; Contact &bull;</h2>
                <article className="contact-container">
                    <div className="contact-info">
                        <div>
                            <FaPhoneAlt />
                            <p>06 31 62 89 07</p>
                        </div>
                        <div>
                            <MdEmail />
                            <p>BUREAUIMAGINAIRE@GMAIL.COM</p>
                        </div>
                    </div>

                    <form id="formulaire-contact" method="post" action="https://formsubmit.co/max.machin@laplateforme.io" onSubmit={sendEmail}  target="_blank">
                            <div>
                                <label htmlFor="name">Votre nom</label>
                                <input 
                                    type="text" 
                                    name="nom" 
                                    id="nom" 
                                    className="contact-nom contact-input"
                                    onChange={handleChange}
                                    value={toSend.nom}
                                    >

                                </input>
                            </div>
                            <div>
                                <label htmlFor="email">Votre e-mail</label>
                                <input 
                                    type="mail" 
                                    name="email" 
                                    id="email" 
                                    className="contact-email contact-input"
                                    onChange={handleChange}
                                    value={toSend.email}
                                    onBlur={emailValidation}
                                    >

                                </input>
                            </div>
                            <div>
                               <label htmlFor="message">Votre message</label>
                                <textarea 
                                    rows="7" 
                                    id="message" 
                                    name="message" 
                                    className="contact-message contact-textarea"
                                    onChange={handleChange}
                                    value={toSend.message}
                                    >

                                </textarea> 
                            </div>
                            <p className="error-formulaire">{isError ? error : ""}</p>
                            <p className="validation-formulaire">{isValide ? validation : ""}</p>
                            <input type="submit" name="submit" className="contact-submit"></input>
                    </form>
                </article>
                
            </section>
                
            </main>
        </div>
    )
}