import * as React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate  } from "react-router-dom";

import Aos from "aos";
import 'aos/dist/aos.css';

import './projet.css';


export default function Projet() {

    Aos.init({duration: 1000});  

    const { pathname } = useLocation();
 
    const locationPath = pathname.split('/')

    const navigate = useNavigate()

    const [pattern, setPattern] = React.useState("")
    

    const elements = [
        {
            pattern: "pattern1",
            id: 0,
            0: [
                '<span className="indication">* Scrollez pour naviguer ! </span>',
                '<img data-aos="fade-up" src="/images/projet1/1.png" alt="image 01" loading=“lazy>',
                '<h1 data-aos="fade-left">Myrtille</h1>',
                '<p> Dans le cadre d’un team building, la directrice de la bibliothèque départementale de la Loire Atlantique nous a contactés afin de créer un jeu d’enquête scénarisé sur mesure et personnalisé pour l’ensemble de son personnel. </p>',
                '<img src="/images/projet1/2.jpg" alt="image 02" loading=“lazy>',
                "<p>C’est ainsi qu’est né le « Projet Myrtille », un scénario inspiré d’Alice au Pays des Merveilles...</p>",
                '<img src="/images/projet1/3.jpg" alt="image 003" loading=“lazy>',
                '<p>Où les joueurs, aidés par le FBI (Fabuleux Bureau Imaginaire) doivent récolter et répondre aux énigmes laissées dans les locaux de la bibliothèque par Myrtille... </p>',
                '<img src="/images/projet1/4.png" alt="image 004" loading=“lazy>',
                '<p>une ancienne usagère recherchée pour trafic de drogue</p>',
                '<p> Accompagnés par L’Equipe Ludique, nous avons entièrement réalisé le game design, le narrative design, la direction artistiques et même les accessoires. </p>',                
                '<img src="/images/projet1/5.jpg" alt="image 004" loading=“lazy>',
                '<p> Une aventure surprise et fédératrice pour l’ensemble des participants ! </p>',
                '<b>Retour</b>',
            ]
        },
        {
            pattern: "pattern2",
            id: 1,
            1: [
            '<span className="indication">* Scrollez pour naviguer ! </span>',
            '<img src="/images/projet2/1.jpeg" alt="image 01" loading=“lazy>',
            '<h1>Le voyageur</h1>',
            '<p> À l’occasion de la nuit de la Lecture à la Médiathèque de Mayenne, le Bureau Imaginaire a été missionné pour réaliser un escape-game clés en main.</p>',
            '<img src="/images/projet2/2.jpg" alt="image 01" loading=“lazy>',
            '<p> d’une durée de 30min à destination d’un public familial. </p>',
            '<img src="/images/projet2/3.jpg" alt="image 01" loading=“lazy>',
            '<p> Nous avons donc imaginé l’histoire d’un mystérieux employé de la médiathèque qui cherche à retrouver la mémoire auprès des joueurs...</p>',
            '<img src="/images/projet2/7.jpg" alt="image 01" loading=“lazy>',
            '<p>Inspiré de Docteur Who, ce jeu invite les joueurs à découvrir les différents espaces de la médiathèque,</p>',
            '<img src="/images/projet2/5.jpg" alt="image 01" loading=“lazy>',
            '<p>ainsi que les technologies proposées par le Fab Lab (impression 3D, Brodeuse numérique,etc)</p>',
            '<img src="/images/projet2/4.jpg" alt="image 01" loading=“lazy>',
            '<b>Retour</b>'
            ]
        },
        {
            pattern: "pattern2",
            id: 2,
            2: [
            '<span className="indication">* Scrollez pour naviguer ! </span>',
            '<img src="/images/projet3/1.jpg" alt="image 01" loading=“lazy>',
            '<h1>Les malles de Wells</h1>',
            '<p>Le Bureau Imaginaire vous propose une activité ludique prête à l’emploi : une énigme à résoudre pour les participants, dans une ambiance années 1930 !</p>',
            '<img src="/images/projet3/2.jpg" alt="image 01" loading=“lazy>',
            '<p> Dans ce jeu, les joueurs reçoivent plusieurs malles de voyage (2 ou 3 en fonction du nombre de participants) datant du début du XXième siècle, qui renferment un mystère. </p>',
            '<img src="/images/projet3/3.jpg" alt="image 01" loading=“lazy>',
            '<p> Ce dernier leur demandera de se creuser les méninges, </p>',
            '<img src="/images/projet3/4.jpg" alt="image 01" loading=“lazy>',
            '<p>mais également de faire des choix moraux qui influeront directement sur l’issue de l’aventure.</p>',
            '<img src="/images/projet3/5.jpg" alt="image 01" loading=“lazy>',
            '<p>Cette activité peut être complétée par un coaching d’équipe, afin de faire ressortir les bonnes pratiques du travail en groupe, et d’identifier des axes d’amélioration pour améliorer les synergies !</p>',
            '<img src="/images/projet3/6.jpg" alt="image 01" loading=“lazy>',
            '<b>Retour</b>'
            ]
        }
        
    ]

    useEffect(() => { 
        
        window.history.scrollRestoration = 'manual'

        const perspectiveOrigin =  {
            x: parseFloat(
              getComputedStyle(document.documentElement).getPropertyValue(
                "--scenePerspectiveOriginX"
              )
            ),
            y: parseFloat(
              getComputedStyle(document.documentElement).getPropertyValue(
                "--scenePerspectiveOriginY"
              )
            ),
            maxGap: 10
        };


       
        afficheElement(elements[locationPath[2]][locationPath[2]])
        window.addEventListener("scroll", moveCamera);
        window.addEventListener("mousemove", moveCameraAngle);
        setSceneHeight(); 

        setPattern(elements[locationPath[2]].pattern)
       


        function moveCameraAngle(event) {
            
            const xGap =
            (((event.clientX - window.innerWidth / 2) * 100) /
                (window.innerWidth / 2)) *
            -1;
            const yGap =
            (((event.clientY - window.innerHeight / 2) * 100) /
                (window.innerHeight / 2)) *
            -1;
            const newPerspectiveOriginX =
            perspectiveOrigin.x + (xGap * perspectiveOrigin.maxGap) / 100;
            const newPerspectiveOriginY =
            perspectiveOrigin.y + (yGap * perspectiveOrigin.maxGap) / 100;
        
            document.documentElement.style.setProperty(
            "--scenePerspectiveOriginX",
            newPerspectiveOriginX
            );
            document.documentElement.style.setProperty(
            "--scenePerspectiveOriginY",
            newPerspectiveOriginY
            );
        }
    
        function moveCamera() {
            document.documentElement.style.setProperty("--cameraZ", window.pageYOffset);
        }
        
        function setSceneHeight() {
            const numberOfItems = elements[locationPath[2]][locationPath[2]].length; // Or number of items you have in `.scene3D`
            const itemZ = parseFloat(
            getComputedStyle(document.documentElement).getPropertyValue("--itemZ")
            );
            const scenePerspective = parseFloat(
            getComputedStyle(document.documentElement).getPropertyValue(
                "--scenePerspective"
            )
            );
            const cameraSpeed = parseFloat(
            getComputedStyle(document.documentElement).getPropertyValue("--cameraSpeed")
            );
        
            const height =
            window.innerHeight +
            scenePerspective * cameraSpeed +
            itemZ * cameraSpeed * numberOfItems;
        
            // Update --viewportHeight value
            document.documentElement.style.setProperty("--viewportHeight", height);
        }
        
        function createElement(elem) {
            return `<div className="elem">
            ${elem}
            </div>`;
        }

        function afficheElement(element) {
            const filmsEl = document.querySelector(".viewport .scene3D");
            let filmsNodes = [];
        
            for (const elem of element) {
            filmsNodes.push(createElement(elem));
            }
        
            filmsEl.innerHTML = filmsNodes.join(" ");
        
            filmsEl.children[13].addEventListener('click', () => {
                navigate('/NosRealisations')
            })
        }



    }, []);

    return (
        
        <main>
            <div className="main">
                
                <div 
                    className={`viewport viewport${locationPath[2]} ${pattern ? pattern : ""}`} 
                    // style={{background: bodyColor}}
                >
                
                    <div className="scene3D-container">
                        <div className="scene3D" id="scene3D"></div>
                    </div>
                </div>
                 
            </div>
            
        </main>

    )
}
