import * as React from "react";
import {useState, useRef, useEffect} from "react";
import {Link} from "react-router-dom";
// import image from "../../img/logo_header.png";
import useScrollListener from "../../hooks/useScrollListener";
import './Header.css';

import { CgMenuRight, CgClose } from 'react-icons/cg';

export function Header (){

    const [navClassList, setNavClassList] = useState([]);
    const scroll = useScrollListener();

    // update classList of nav on scroll
    useEffect(() => {
        const _classList = [];

        if (scroll.y > 10 && scroll.y - scroll.lastY > 0)
        _classList.push("nav-bar--hidden");

        if (scroll.y < 10)
        _classList.push("nav-top-clean")

        setNavClassList(_classList);
    }, [scroll.y, scroll.lastY]);



    const navRef = useRef()

    const showNav = () => {
        navRef.current.classList.toggle("responsive_nav")
    }
    
    return (
        <header>

            {/**--------------Mobile menu--------------- */}
            <section className="mobile-menu">

                <Link to="/"><img src='/images/logo_header.png' alt="Logo" width="130"></img></Link>

                <nav ref={navRef}>  
                    <article><p>01/</p><Link to="/" onClick={showNav}>Home</Link></article> 
                    <article><p>02/</p><Link to="/NosRealisations" onClick={showNav}>Nos réalisations</Link></article>
                    <article><p>03/</p><Link to="/APropos" onClick={showNav}>Qui sommes-nous?</Link></article>
                    <article><p>04/</p><Link to="/TravaillerEnsemble" onClick={showNav}>Travailler ensemble</Link></article>
                    <article><p>05/</p><Link to="/FabriqueProjet" onClick={showNav}>La Fabrique à Projets</Link></article>
                    
                    <button className="nav-close-btn" onClick={showNav}>
                        <CgClose />
                    </button>
                </nav>

                <button className="nav-btn" onClick={showNav}>
                    
                    <CgMenuRight /> 
                </button>

            </section>

            {/**--------------Desktop menu--------------- */}
            <section className="desktop-menu">

                <nav className={navClassList.join(" ")}>   
                    <Link to="/" >Home</Link>
                    <Link to="/NosRealisations" >Nos réalisations</Link>
                    <Link to="/APropos">Qui sommes-nous?</Link>
                    {/* <Link to="/"><img src={image} alt="Logo" width="130"></img></Link> */}
                    <Link to="/TravaillerEnsemble">Travailler ensemble</Link>
                    <Link to="/FabriqueProjet">La Fabrique à Projets</Link>
                </nav>

            </section>
        </header>
    )
}

