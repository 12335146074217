import React, { useEffect } from 'react'
import { useState } from 'react';




export function PanelAdmin() {

    const [data , setData] = useState([])

    useEffect(() => {
       fetch("./data.json")
        .then((res) => res.json()
        .then((data) => {
            console.log(data)
            setData(data)
            
        })
        .catch((err) => {
            console.log(err)
        })
        )
    }, [])

    return (
        <main>
            <section className='Panel-admin-section'>
                <article>
                    <h2>Gestion des projets</h2>
                        {data.map((item, index) => {
                            return (
                                <div key={item.id}>
                                    <p>{item.title}</p>
                                    <button >Supprimer</button>
                                </div>
                            )
                        })}
                </article>
            </section>
        </main>
    )
}

