import React from "react";

const Circle = ({className, children, classNameTitle, title}) => {
    return (
        <div className={classNameTitle}>
            <div className={className}>
                {children}
            </div>
            <p>{title}</p>
        </div>
        
    )
}

export default Circle