import * as React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import 'aos/dist/aos.css';

import { Parallax } from 'react-scroll-parallax';

import './Home.css';

import Logo from "../../Components/Logo.js";


function ListConcept(){
  return (
    <ol>
      <ListItem dataaos={'fade-right'} title="Escape game">
        Les participants sont «enfermés» et doivent résoudre une série d’énigmes pour sortir, ou les participants sont confrontés à 
        une problématique, et doivent résoudre une série d’énigmes pour parvenir au dénouement.
      </ListItem>
      <ListItem dataaos={'fade-left'} title="Jeu de piste scénarisé">
        Les participants sont intégrés dans une histoire, qui les amène à devoir franchir des jalons, afin de parvenir au dénouement.
      </ListItem>
      <ListItem dataaos={'fade-up-left'} title="Jeu d'enquête">
        Les participants sont confrontés à une problématique, et doivent enquêter afin de parvenir au dénouement, notamment en discutant avec des comédiens.
      </ListItem>
      <ListItem dataaos={'fade-up'} title="coaching scénarisé">
        Les participants endossent un rôle dans un contexte précis, et vivent une expérience de jeu de rôle grandeur nature : des coachs professionnels observent les softskills des 
        participants, puis fournissent une analyse et des pistes d’amélioration individuelles et collectives
      </ListItem>
      <ListItem dataaos={'fade-up-right'} title="Jeu de société personnalisé">
        Les participants jouent de manière collaborative ou compétitive à un jeu de société designé en fonction de vos objectifs et enjeux
      </ListItem> 
    </ol>
  )
}

function ListItem({title,children, dataaos}){
  const [show, setShow] = React.useState(false);
  return (
    <li data-aos={dataaos} className={`list-item $`} onClick={() => setShow(prev => !prev)}>
      <p>{title}</p>
      <span className = {show ? `block` : 'none'}>
        {children}
      </span>
    </li>
  );
}

function UniqueProjet({id, title, contenu, images}){

  const navigate = useNavigate();


  function setYoffset(){
    window.scrollTo(0, 0);
  }

  // function refreshPage() {
  //     window.location.reload(false);
  // }


    return (
     
        <article className="sample-card">
          <div className="img-header-realisation"> 
              <img 
              src={images[0]} 
              alt="projet myrtille">
              </img>
            </div>
          
            <div className="realisation-content">
              <div className="img-content-realisation"> 
                <img 
                  src={images[1]}
                  alt="chat projet myrtille">
                </img>
              </div>

              <div className="content-area">
                <h2>{title}</h2>
                <p>{contenu}</p>
                <div  onClick={() => {navigate(`NosRealisations/${id}`); setYoffset();}}>
                  <button>Découvrir</button>
                </div>
              </div>
            </div>
        </article>
      
    )
}

export function Home() {

  const { pathname } = useLocation();
  const currentPath = pathname.split('/')

  const data = [
    {
      id: 0,
      title: "Myrtille",
      contenu: "un scénario inspiré d’Alice au Pays des Merveilles, où les joueurs, aidés par le FBI (Fabuleux Bureau Imaginaire) doivent récolter et répondre aux énigmes laissées dans les locaux de la bibliothèque par Myrtille, une ancienne usagère recherchée pour trafic de drogue.",
      images: [
        "./images/projet1/6.jpg" ,
        "./images/projet1/pres2.jpg"
      ]
    } , { 
      id: 1,
      title: "Le voyageur",
      contenu: "L’histoire d’un mystérieux employé de la médiathèque qui cherche à retrouver la mémoire auprès des joueurs. Inspiré de Docteur Who, ce jeu invite les joueurs à découvrir les différents espaces de la médiathèque ainsi que les technologies proposées par le Fab Lab.",
      images: [
        "./images/projet2/pres1.jpg" ,
        "./images/projet2/4.jpg"
      ]
    } , {
      id: 2,
      title: "Wells-Mitchell",
      contenu: "L’histoire d’un mystérieux employé de la médiathèque qui cherche à retrouver la mémoire auprès des joueurs. Inspiré de Docteur Who, ce jeu invite les joueurs à découvrir les différents espaces de la médiathèque ainsi que les technologies proposées par le Fab Lab.",
      images: [
        "./images/projet3/1.jpg" ,
        "./images/projet3/2.jpg"
      ]
    }
  ]

  useEffect(() => {
    if(!currentPath[1] && !currentPath[2]){

      Aos.init({duration: 1000});

      const spaceHolder = document.querySelector('.space-holder');
      const horizontal = document.querySelector('.horizontal');
      

      function calcDynamicHeight(ref) {
        const vw = window.innerWidth;
        const vh = window.innerHeight;
        const objectWidth = ref.scrollWidth;
        return objectWidth - vw + vh + 150; // 150 is the padding (in pixels) desired on the right side of the .cards container. This can be set to whatever your styles dictate
      }

      spaceHolder.style.height = `${calcDynamicHeight(horizontal)}px`;

      window.addEventListener('scroll', () => {
        const sticky = document.querySelector('.sticky');
        horizontal.style.transform = `translateX(-${sticky.offsetTop}px)`;
      });
      
      window.addEventListener('resize', () => {
        spaceHolder.style.height = `${calcDynamicHeight(horizontal)}px`;
      });
    }
  }, [currentPath])
  
  return (
    
    <div>
      <main>
        {/* ---------------HOME LOGO SECTION ----------------*/}
        <section className="home-logo-section">
          <Logo />
          <div className="container-chevron">
            <div className="chevron"></div>
            <div className="chevron"></div>
            <div className="chevron"></div>
          </div>
        </section>

        {/* ---------------HOME PRESTATIONS ----------------*/}
        <section className="container container-presta" style={{overflow: 'hidden'}}>

          <div className='title-content-pres' id='title-content-pres'>
            <p><b>&bull;</b> Nos solutions sur mesure <b>&bull;</b></p>
          </div>

          <ListConcept />

          {/*--------------MARQUEE MOBILE----------------*/}
          <section className="section-marquee">
            <article className="marquee-article">
              <div className="marquee-container marquee1">
                <Parallax translateX={[40, 10]}>
                  <span className="marquee">Et toutes sortes </span>
                </Parallax>
              </div>
              </article>
              <article className="marquee-article">
              <div className="marquee-container marquee2">
                <Parallax translateX={[0, 50]}>
                  <span className="marquee">de solutions </span>
                </Parallax>
              </div>
              </article> 
              <article className="marquee-article">
              <div className="marquee-container marquee3">
                <Parallax translateX={[70, 0]}>
                  <span className="marquee">ludiques adaptées</span>
                </Parallax>
              </div>
              </article>
              <article className="marquee-article">
                <div className="marquee-container marquee4">
                <Parallax translateX={[ 0, 35]}>
                  <span className="marquee ">  à votre contexte !</span>
                </Parallax>
                </div>
              </article>
            </section>

            {/*--------------MARQUEE TABLETTE ----------------*/}
            <section className="section-marquee-tablet">
              <article className="marquee-article">
                <div className="marquee-container">
                  <Parallax translateX={[-10, 30]}>
                    <span className="marquee">Et toutes sortes de solutions</span>
                  </Parallax>
                </div>
              </article>
              <article className="marquee-article">
                <div className="marquee-container">
                  <Parallax translateX={[35, -30]}>
                    <span className="marquee">ludiques adaptées à votre contexte</span>
                  </Parallax>
                </div>
              </article>
            </section>

            {/*--------------MARQUEE DESKTOP ----------------*/}
            <section className="section-marquee-desktop">
              <article className="marquee-article">
                <div className="marquee-container">
                  <Parallax translateX={[20, -30]}>
                    <span className="marquee">Et toutes sortes de solutions ludiques adaptées à votre contexte</span>
                  </Parallax>
                </div>
              </article>
            </section>
          
            <div data-aos="fade-up" className="callAction action-home">
              <p>Envie de créer votre aventure ? </p>
              <p>Travailler ensemble</p>          
            </div>

        </section>

        {/* ---------------HOME REALISATIONS ----------------*/}

        <div className='title-content-pres'>
          <p><b>&bull;</b> Nos réalisations <b>&bull;</b></p>
        </div>

        <section className="container-horizontal">
          <div className="space-holder">
            <div className="sticky">
              <div className="horizontal">
                <section role="feed" className="cards">

                  <UniqueProjet id={data[0].id} title={data[0].title} images={[data[0].images[0] , data[0].images[1]]} contenu={data[0].contenu}/>
                  <UniqueProjet id={"1"} title={data[1].title} images={[data[1].images[0] , data[1].images[1]]} contenu={data[1].contenu}/>
                  <UniqueProjet id={"2"} title={data[2].title} images={[data[2].images[0] , data[2].images[1]]} contenu={data[2].contenu}/>
                  
                </section>
              </div>
            </div>
          </div>
        </section>
        
        {/* ---------------HOME ENJEUX & OBJECTIFS ----------------*/}
        <div data-aos="fade-up" className='title-content-pres pres-enjeux' >
            <p><b>&bull;</b> Nos Enjeux et objectifs <b>&bull;</b></p>
        </div>
       
        <section className="container-enjeux">
          

          <h3 className="floating center-item" data-aos="fade-up">Bureau Imaginaire</h3>
          <ol>

              <li className="floating1 item-un item" data-aos="fade-up">Softskills</li>
              <li className="floating2 item-deux item" data-aos="fade-up">Agilité</li>
              <li className="floating3 item-trois item" data-aos="fade-up">Compétences managériales</li>
              <li className="floating4 item-quatre item" >Créativité</li> 
              <li className="floating5 item-cinq item" data-aos="fade-up">Communication entre services</li>
              <li className="floating6 item-six item" data-aos="fade-up">Potentiels évolutifs</li>
              <li className="floating7 item-sept" data-aos="fade-up">Vos objectifs et enjeux spécifiques</li>
          </ol>
        </section>

      </main>
    </div>
  )
}
